<template>
  <div class="layout">
    <div
      v-if="route.fullPath !== '/'"
      class="pre-header"
    >
      <div class="adaptive-img imgWrapper">
        <NuxtImg
          class="bg-cover"
          fit="contain"
          loading="lazy"
          :src="String(properties.background?.value)"
          width="1920"
          height="300"
        />
        <h1
          v-if="properties.background_text?.value"
          class="page-title"
        >
          {{ properties?.background_text?.value }}
        </h1>
        <div class="underline"></div>
        <img
          src="~/assets/img/svg/figures/pre-header/square.svg"
          alt="square"
          class="square"
        >
        <img
          src="~/assets/img/svg/figures/pre-header/pattern.svg"
          alt="square"
          class="pattern"
        >
      </div>
    </div>
    <Breadcrumbs
      v-if="route.fullPath !== '/'"
      :breadcrumbs="breadcrumbs"
    />
    <layout-placeholder
      :blocks="blocks"
      :external-data="$attrs?.externalData"
    />
  </div>
</template>

<script setup lang="ts">
import type { IBreadcrumbs } from '~/components/UI/Breadcrumbs.vue';
import Breadcrumbs from '~/components/UI/Breadcrumbs.vue';
import type { IContentBlockCms } from '#sitis/internal/controllers/cms/models/Content';
import { useRoute } from '#app';
import { useServerSeoMeta } from '#imports';

const route = useRoute();
const props = defineProps<{
  blocks: IContentBlockCms [];
	externalData?: any;
  breadcrumbs:IBreadcrumbs[];
  properties: {
    announce?: string | null;
    background?: {value: string},
    background_text?: {value: string}
  }
}>();

const url = useRequestURL();
useServerSeoMeta({
  ogUrl: () => url.href
});
</script>

<style lang="scss" scoped>
.pre-header {
  width: 100%;
  height: 200px;
  position: relative;
  top: -104px;
  left: 0;
  z-index: -1;
}

.imgWrapper {
  position: relative;
  width: 100%;
  height: 300px;

  & > .bg-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -2;
    object-fit: cover;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 300px;
    background: rgb(108 153 9);
    top: 0;
    left: 0;
    z-index: -1;
    mix-blend-mode: multiply;
  }
}

.underline {
  width: 30%;
  height: 5px;
  position: absolute;
  background: #ABDD39;
  bottom: 0;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
}

.square {
  position: absolute;
  bottom: 0;
  z-index: 3;
  left: 0;
}

.pattern {
  position: absolute;
  bottom: 20px;
  z-index: 3;
  right: 20px;
}

.page-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 26px;
  color: var(--text-white);
  font-size: 64px;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-weight: 400;
}

@media(max-width: 1023px) {
  .pattern {
    display: none;
  }
}

@media(max-width: 640px) {
  .square {
    display: none;
  }

  .page-title {
    font-size: 36px;
    bottom: 10px;
  }

  .imgWrapper {
    height: 250px;
  }

  .pre-header {
    height: 150px;
  }
}
</style>
